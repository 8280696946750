@font-face {
    font-family: teko;
    src: url(fonts/Teko/Teko-Regular.ttf);
}

@font-face {
    font-family: teko-medium;
    src: url(fonts/Teko/Teko-Medium.ttf);
}

@font-face {
    font-family: teko-bold;
    src: url(fonts/Teko/Teko-Bold.ttf);
}

@font-face {
    font-family: teko-semibold;
    src: url(fonts/Teko/Teko-SemiBold.ttf);
}

@font-face {
    font-family: teko-light;
    src: url(fonts/Teko/Teko-Light.ttf);
}

@font-face {
    font-family: edit-undo;
    src: url(fonts/editundo.ttf);
}

@font-face {
    font-family: edit-undo-line;
    src: url(fonts/editundoline.ttf);
}

@font-face {
    font-family: press-start;
    src: url(fonts/PressStart2P-Regular.ttf);
}