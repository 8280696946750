@font-face {
  font-family: teko;
  src: url("Teko-Regular.e1038e64.ttf");
}

@font-face {
  font-family: teko-medium;
  src: url("Teko-Medium.fd86026e.ttf");
}

@font-face {
  font-family: teko-bold;
  src: url("Teko-Bold.b3bb7208.ttf");
}

@font-face {
  font-family: teko-semibold;
  src: url("Teko-SemiBold.74db6a8e.ttf");
}

@font-face {
  font-family: teko-light;
  src: url("Teko-Light.a04e86e6.ttf");
}

@font-face {
  font-family: edit-undo;
  src: url("editundo.ae4fc082.ttf");
}

@font-face {
  font-family: edit-undo-line;
  src: url("editundoline.e77e9342.ttf");
}

@font-face {
  font-family: press-start;
  src: url("PressStart2P-Regular.5b980536.ttf");
}
/*# sourceMappingURL=index.8aae42ed.css.map */
